import React from 'react';
import { serviceData } from '../../../utils/data';
import { Link } from 'react-router-dom';
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import ServiceImage from '../../../assets/images/service-card-image.svg';
import './style/serviceComponent.css';

const ServiceComponent = () => {
  return (
    <section className='service-section w-full py-10 flex flex-col justify-center items-center relative'>
      <div className='sunlight-services w-[30%] h-[30%] absolute left-0 flex-shrink-0 rotate-[-22.538deg] -z-10 rounded-[490.219px]'></div>
       <div className='container heading-container w-full max-w-[85%] md:w-[85%] lg:w-[85%]'>
         <div className='text-heading'>
            <h2 className='text-white text-[48px] font-normal'><span className='text-[#D205FC]'>Services</span> We Offer </h2>
         </div>
       </div>
       <div className='main-container w-full flex flex-col justify-center items-center py-10'>
        <div className="service-list border-t-[1px] border-t-[#636363] w-full">
          {serviceData.map((service, index)=>(
            <div key={index} className='w-full service-item border-b-[1px] border-b-[#636363] py-10 flex flex-col justify-center items-center relative group'>
              {/* Background Gradient and Image on Hover */}
              <div className="service-background absolute inset-0 bg-transparent transition-all duration-500 ease-in-out"></div>
              {/* Image (show on hover) */}
              <div className="service-image absolute bottom-0 left-[12%] opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100 overflow-hidden w-[400px]">
                <img src={ServiceImage} alt={service.title} className=' h-auto w-auto object-cover opacity-50' />
              </div>
              <div className='content-container w-full max-w-[85%] flex flex-row justify-between items-center gap-12'>
                 <div className='number'>
                   <h2 className='text-white text-[80px] font-bold'>{service.number}</h2>
                 </div>
                 <div className='text-content flex flex-row justify-between gap-20 mx-6 items-center w-full'>
                   <h3 className='text-white w-[20%] text-[23px] group-hover:font-bold'>
                      {service.title}
                   </h3>
                   <p className='text-[#C4C4C4] w-[80%] text-[15px]'>{service.description}</p>
                 </div>
                 <div className='arrow-url'>
                   <Link to={service.url} className='text-white'>
                      <HiOutlineArrowLongRight className='p-3 border border-white text-[60px] rounded-[50%]'/> 
                   </Link>
                 </div>
              </div>      
            </div>
          ))}
        </div>
       </div>
    </section>
  )
}

export default ServiceComponent

import React from "react";
import "../styles/footer.css";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedin,
  // FaYoutube,
  FaPhoneVolume,
  FaAddressCard,
  FaEnvelope
} from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import FooterLogo from '../assets/images/footer-logo(proco).svg';
import Ansilbyte from '../assets/images/ansilbyte-logo.svg';
import Infivent from '../assets/images/infivent-logo.svg';


const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-main">
          <div className="container grid grid-four-column">
            <div className="footer-logo">
              <NavLink to="/">
                <img src={FooterLogo} alt="logo" />
              </NavLink>
              {/* <h4 className="logo-title">Procohat Technologies Pvt. Ltd.</h4> */}
              {/* <div className="partner-logos mt-10 flex flex-row justify-center items-center w-[80%] bg-[#34095D] py-5 gap-3">
                <NavLink to="/">
                  <img src={Ansilbyte} alt="logo" />
                </NavLink>
                <div className="w-[1.5px] h-[20px] bg-white rounded-[10px]"></div>
                <NavLink to="/"> 
                  <img src={Infivent} alt="logo" />
                </NavLink>

              </div> */}


            </div>
            <div className="footer-quickLink">
              <h4 className="quickLink-title footer-title">Learn More</h4>
              <ul className="quickLink-list">
                <li className="quickLint-list-items">
                  <NavLink to="/about" className="item">
                    About Us
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/services" className="item">
                    Our Services
                  </NavLink>
                </li>
                {/*      <li className="quickLint-list-items">
                  <NavLink to="/case-studies" className="item">
                    Case Studies
                  </NavLink>
                </li> */}
                {/* <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    Infivent
                  </NavLink>
                </li> */}
                <li className="quickLint-list-items">
                  <NavLink to="/contact" className="item">
                    Contact Us
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/privacy-policy" className="item">
                    Privacy Policy
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/cookie-policy" className="item">
                    Cookie Policy
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/termsandcondition" className="item">
                    Terms & Condition
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/refund-policy" className="item">
                    Refund Policy
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-infivint">
              <h4 className="infivent-title footer-title">Infivent</h4>
              <ul className="quickLink-list">
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    Saas/Development
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    Cloud based BI Solutions
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    AI-ML
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    Design
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    Data Analysis
                  </NavLink>
                </li>
                <li className="quickLint-list-items">
                  <NavLink to="/infivent" className="item">
                    ServiceNow
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-left-column">
              <div className="inner-container grid grid-two-column">
                <div className="footer-contact">
                  <h4 className="contact-title footer-title">Contact Us</h4>
                  {/* <a className="contact-number flex flex-row items-center gap-[10px]" href="tel: +919284384585">
                    <FaPhoneVolume className="phone-icon" /> +91 9284384585
                  </a> */}
                  <p className="mt-2">
                    <a className="contact-number flex flex-row items-center gap-[10px]" href="mailto:info@example.com">
                      <FaEnvelope className="mail_icon" /> info@procohat.com
                    </a>
                  </p>

                  <p className="contact-address">
                    <FaAddressCard className="address-icon" /> Plot No, 20, IT Park Rd, Parsodi, Nagpur, Maharashtra 440022
                  </p>
                </div>
                <div className="footer-social">
                  <h4 className="social-title footer-title">Social</h4>
                  <div className="social-icon-list">
                    <div className="social-items">
                      <a
                        className="icon-link"
                        href="https://www.facebook.com/ProCohat/"
                      >
                        <FaFacebookF className="icon" />
                      </a>
                    </div>
                    <div className="social-items">
                      <a
                        className="icon-link"
                        href="https://www.instagram.com/procohat/"
                      >
                        <BiLogoInstagramAlt className="icon" />
                      </a>
                    </div>
                    <div className="social-items">
                      <a
                        className="icon-link"
                        href="https://twitter.com/ProCohat"
                      >
                        <FaTwitter className="icon" />
                      </a>
                    </div>
                    <div className="social-items">
                      <a 
                        className="icon-link"
                        href="https://www.linkedin.com/company/procohat/"
                      >
                        <FaLinkedin className="icon" />
                      </a>
                    </div>
                    {/* <div className="social-items">
                      <a className="icon-link" href="/#">
                        <FaYoutube className="icon" />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="footer-newsletter">
                <form action="">
                  <input
                    type="email"
                    autoComplete="off"
                    placeholder="Email Address"
                  />
                  <input type="submit" value="Subscribe" />
                </form>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="divider-line"></div>
          <div className="copyright">
            <h5>
              ©{new Date().getFullYear()} ProCohat Technologies Pvt. Ltd. | All
              Rights Reserved
            </h5>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
